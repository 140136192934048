import React, { useEffect } from "react";
import { DateTime } from "luxon";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import CharaFaceImg from "./chara-face";
import Typography from '../styles/typography';
import { NewBadge } from "./badge";

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: i => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: i * 0.1,
    },
  }),
}

export const NewMemberList = (props) => {
  const now = DateTime.local();
  const limit = now.minus({ days: 60 });
  const data = useStaticQuery(graphql`
    query {
      allMembersJson {
        edges {
          node {
            slug
            profile {
              name {
                first
                full
              }
            }
            images {
              face
            }
            current_job {
              key
              name
              type
            }
            join_date
          }
        }
      }
    }
  `);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const newMembers = data.allMembersJson.edges.filter(({node}) => DateTime.fromISO(node.join_date) > limit);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const newMemberList = () => {
    return newMembers.map(({ node }, index) => {
      return (
        <motion.li className="member-item" key={index} custom={index} variants={item}>
          <MemberFaceCard member={node} />
        </motion.li>
      );
    });
  }

  if (!newMembers.length) return null;

  return (
    <>
      <h3 css={css`
        ${Typography.h5}
      `}>New Members</h3>
      <motion.ul
        ref={ref}
        animate={controls}
        css={NewMemberListStyle}
        variants={container}
        initial="hidden"
        {...props}>
        {newMemberList()}
      </motion.ul>
    </>
  );
}

export const MemberFaceCard = ({ member }) => {
  const now = DateTime.local();
  const joinDate = DateTime.fromISO(member.join_date);
  const isNewest = (joinDate.plus({ days: 30 }) > now);

  return (
    <div css={MemberFaceCardStyle}>
      <Link to={`/members/${member.slug}`}
        className="member-pointer"
        title={member.profile.name.full}>
        { isNewest && <NewBadge top={0} right="-.5rem" title="New Member" /> }
        <CharaFaceImg filename={member.images.face} className="member-image" width={40} height={40} />
        <span className="member-name">{member.profile.name.full}</span>
      </Link>
    </div>
  );
}

const MemberFaceCardStyle = css`
  position: relative;

  .member-pointer {
    display: block;

    color: #6D6F70;

    &:hover {
      text-decoration: none;
      color: #2B2D2E;
    }
  }

  .member-image {
    display: inline-block;
    transition: transform .1s ease-in-out;
    margin-right: 1em;
    vertical-align: middle;

    &:hover {
      transform: scale(1.25);
    }

    &:active {
      transform: scale(1.125);
    }
  }

  .member-name {
    display: inline-block;

    text-align: center;
    font-weight: bold;
  }
`;

const NewMemberListStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding-inline-start: 0;

  list-style: none;

  .member-item {
    margin: 1rem;
  }

  .list-more {
    margin: 1rem;

    .more-pointer {
      display: block;
      widtH: 72px;
      height: 72px;
      border-radius: 50%;

      line-height: 72px;
      text-align: center;
      font-weight: bold;

      background: #EAEDEE;
      color: #828485;

      &:hover {
        text-decoration: none;

        background: #FAFDFE;
      }
    }
  }
`;
